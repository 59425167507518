// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import "./App.css";
import header from "./assets/header.md";
import intro from "./assets/intro.md";
import avi from "./assets/avi.jpg";

function App() {
  const [head, setHead] = useState("");
  const [main, setMain] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const headerRef = useRef(null);

  useEffect(() => {
    fetch(header)
      .then((res) => res.text())
      .then((text) => setHead(text));
    fetch(intro)
      .then((res) => res.text())
      .then((text) => setMain(text));
    MapRender();
    setIsLoading(false);

    // Color cycling effect
    const colors = ['#F97171', '#F8E08B', '#93D3F6', '#D1A1F6'];
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      if (headerRef.current) {
        headerRef.current.style.color = colors[currentIndex];
        currentIndex = (currentIndex + 1) % colors.length;
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const MapRender = () => {
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    const container = document.getElementById("canvas");
    renderer.setSize(600, 600);
    container.appendChild(renderer.domElement);
    const onRenderFunctions = [];
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(25, 1000 / 1000, 0.01, 1000);
    camera.position.z = 10;
    camera.position.y = 2;
    scene.fog = new THREE.Fog(0x000, 0, 45);
    (function () {
      const light = new THREE.DirectionalLight("white", 5);
      light.position.set(0.5, 0.0, 2);
      scene.add(light);
    })();
    const heightMap = THREEx.Terrain.allocateHeightMap(256, 256);
    THREEx.Terrain.simplexHeightMap(heightMap);
    const geometry = THREEx.Terrain.heightMapToPlaneGeometry(heightMap);
    THREEx.Terrain.heightMapToVertexColor(heightMap, geometry);
    // INFO: color here.
    const material = new THREE.MeshBasicMaterial({
      wireframe: true,
      color: 0xFFFFFF,
    });
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);
    mesh.lookAt(new THREE.Vector3(0, 1, 0));
    mesh.scale.y = 2.5;
    mesh.scale.x = 4;
    mesh.scale.z = 0.2;
    mesh.scale.multiplyScalar(10);
    onRenderFunctions.push(function (delta) {
      mesh.rotation.z += 0.075 * delta;
    });
    onRenderFunctions.push(function () {
      renderer.render(scene, camera);
    });
    let lastTimeMsec = null;
    requestAnimationFrame(function animate(nowMsec) {
      requestAnimationFrame(animate);
      lastTimeMsec = lastTimeMsec || nowMsec - 1000 / 60;
      const deltaMsec = Math.min(200, nowMsec - lastTimeMsec);
      lastTimeMsec = nowMsec;
      onRenderFunctions.forEach(function (onRenderFct) {
        onRenderFct(deltaMsec / 1000, nowMsec / 1000);
      });
    });
  };

  const Stars = () => {
    return (
      <div className="stars-container">
        <div className="star star-1" />
        <div className="star star-2" />
        <div className="star star-3" />
        <div className="star star-4" />
      </div>
    );
  };

  return (
    <div className="App theme-new">
      <div className="header">
        <h1 ref={headerRef}>Hunter Goodreau</h1>
        <ReactMarkdown children={head} remarkPlugins={[gfm]} />
      </div>
      {/* <Stars /> */}
      <br />
      <span className="emoji">🐇 💻 🛠️ ☕️</span>
      <ReactMarkdown children={main} remarkPlugins={[gfm]} />
      <div
        id="canvas"
        style={isLoading ? { display: "none" } : { display: "inherit" }}
      />
    </div>
  );
}

export default App;
